<template>
  <v-container fluid class="pa-8 pa-sm-15">
    <div class="header-block d-flex align-center justify-space-between pb-8 pb-sm-15">
      <div class="d-flex align-center">
        <portal to="pageTitle" :disabled="checkMd">
          <div class="font-page-header pr-5 pr-sm-15">destination</div>
        </portal>
        
        <ActionButton @click="closeAction">Manage destinations</ActionButton>
      </div>
      
      <div>
        <CancelButton @click="closeAction">Close</CancelButton>
      </div>
    </div>
    
    <CardDestination
      class="border-box py-15 px-12 cardBg rounded-20"
      :class="checkSm ? 'py-sp-23 px-sp-20' : 'py-sm-25 px-sm-25'"
      :description="description"
      :loadDetail="loading"
      :destinationSlug="destinationSlug"
      :destination="detailDestination"
      :onShowResetCaps="onShowResetCaps"
      :loadDestinationCaps="loadDestinationCaps"
    />
  
  </v-container>
</template>

<script>
import ActionButton from '@/components/buttons/ActionButton'
import CancelButton from '@/components/buttons/CancelButton'
import CardDestination from '@/components/cards/CardDestination'

import { getDetailDestination } from '@/api/destination-routes'

export default {
  name: 'DetailDestination',
  components: { ActionButton, CancelButton, CardDestination },
  data: () => ({
    loading: false,
    destinationSlug: null,
    onShowResetCaps: false,
    loadDestinationCaps: true,
    detailDestination: null,
    description: {
      title: 'Setup Your Destination',
      text: [],
    },
  }),
  computed: {
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
  },
  mounted() {
    if (this.$route.params.slug) {
      this.destinationSlug = this.$route.params.slug
      this.onShowResetCaps = true
      this.loadDestinationCaps = true
      this.goToLoadDetailDestination()
    }
  },
  methods: {
    closeAction() {
      this.$router.push({ name: 'ManageDestinations' })
    },
    async goToLoadDetailDestination() {
      this.loading = true
      
      const { success, payload, message } = await getDetailDestination(this.destinationSlug)
      
      if (success) {
        this.detailDestination = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loading = false
    },
  },
}
</script>
